<template>
  <div :class="`m-3 wrapper  wrapper--${bu}`">
    <Loading v-if="loading" />
    <table v-else :class="`w-100 table--default table__border--${bu}`">
      <thead :class="`table__head--${bu}`">
        <tr>
          <th class="table__head--text">Item</th>
          <th class="table__head--text">Inkoper</th>
          <th class="table__head--text">Stadagen</th>
          <th class="table__head--text">Inkoop</th>
          <th class="table__head--text">Kostenbedag</th>
          <th class="table__head--text">Vraagprijs</th>
          <th class="table__head--text">Inspectierapport</th>
        </tr>
      </thead>

      <tbody>
        <tr
          v-for="(item, key) in inspection_report_data"
          :key="key"
          class="table__row--color"
        >
          <td class="table__cell--default">
            <ImageHover :itemnummer="item.nummer" :bu="bu" />
          </td>
          <td class="table__cell--default">{{ item.naam }}</td>
          <td class="table__cell--default text-center">{{ item.stadagen }}</td>
          <td class="table__cell--default table__data--right">€ {{ item.inkoopbedrag }}</td>
          <td class="table__cell--default table__data--right">€ {{ item.kostenbedrag }}</td>
          <td class="table__cell--default table__data--right">€ {{ item.Vraagprijs }}</td>
          <td class="table__cell--default text-center"><router-link :to="{ name: 'inspectionrapport', params: { itemno: item.nummer } }"
        >Laagste score: {{ item.inspectierapport.substr(0, 1) }}
      </router-link></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import request from "@/functions/request.js";
import Loading from "@/components/Loading.vue";
import ImageHover from "@/components/ImageHover.vue";

export default {
  props: ["bu"],
  components: { Loading, ImageHover },
  data: () => ({
    loading: true,
    inspection_report_data: null,
  }),
  created() {
    this.getData(this.bu);
  },
  watch: {
    bu(newbu) {
        this.loading = true;
      this.getData(newbu);
    },
  },
  methods: {
    getData(bu) {
      request(`inspection-report-list/${bu}`, "GET").then(
        ({ inspection_report_data }) => {
          this.inspection_report_data = inspection_report_data;
          this.loading = false;
        }
      );
    },
  },
};
</script>
